<template>
  <div class="monishiti">
    <heade></heade>
    <div class="con_wrap">
      <div class="con_box nice-scroll">
        <form class="layui-form" onSubmit="return false">
          <div class="exam">
            <div
              class="exam-list"
              v-for="(exams, indexs) in exams"
              :key="indexs"
              :id="indexs + 1"
            >
              <div class="exam-title">
                {{ indexs + 1 }}. {{ exams.name }} {{ exams.score }}分
              </div>
              <div class="exam-picture" v-if="exams.images.length > 0">
                <img
                  v-for="(images, images_index) in exams.images"
                  :key="images_index"
                  class="img-thumbnail img-responsive"
                  :src="images.image"
                />
              </div>

              <div
                class="exam-chose"
                v-for="(choses, index) in exams.choses"
                :key="index"
              >
                <div class="exam-chose-item">
                  <input
                    type="radio"
                    :name="examChoseName(exams.id, indexs)"
                    :value="examChoseValue(index)"
                    :title="examChoseTitle(index, choses.name)"
                    lay-filter="examChose"
                    :attr-th="indexs"
                    attr-t="c"
                    :examid="exams.id"
                    :index="indexs + 1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="pull-right">
            <input
              type="submit"
              lay-submit
              lay-filter="examSubmit"
              class="layui-btn layui-btn-radius"
              value="提交试卷"
              @click="formSubmit()"
            />
          </div>
        </form>
      </div>
    </div>

    <div class="left-fixed">
      <div class="exam-left-time">
        <div class="exam-left-time-title">
          <strong>答题剩余时间</strong>
        </div>
        <div class="exam-left-time-content"></div>
      </div>
      <div class="all-ecstatus">
        <div class="all-ecstatus-content">
          <div class="chose">
            <div class="all-ecstatus-title">
              <b>单选题：</b>
            </div>
            <div class="all-ecstatus-content-c">
              <span v-for="(item, index) in exams" :key="index">
                <a
                  v-if="index < 9"
                  href="javascript:void(0);"
                  @click="naver(index + 1)"
                  >0{{ index + 1 }}</a
                >
                <a
                  v-if="index >= 9"
                  href="javascript:void(0);"
                  @click="naver(index + 1)"
                  >{{ index + 1 }}</a
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import heade from "@components/heade.vue";
import foot from "@components/foot.vue";
import { scroll } from "@utils/scroll";
import { getExams, saveExam } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  data() {
    return {
      exams: [],
      uanswer: {},
    };
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
  mounted: function () {
    let that = this;
    getExams().then(function (res) {
      that.$set(that, "exams", res.data);
    });
    scroll($(".nice-scroll"));
    layui.use(["form"], function () {
      let form = layui.form;
      form.on("radio(examChose)", function (data) {
        if ($(data.elem).attr("attr-t") == "c") {
          $(".all-ecstatus-content .chose .all-ecstatus-content-c")
            .children("span")
            .eq($(data.elem).attr("attr-th"))
            .addClass("active");
          let examIndex = $(data.elem).attr("index");
          that.uanswer[examIndex] = {};
          that.uanswer[examIndex][$(data.elem).attr("examid")] = data.value;
        }
      });
    });
  },
  created: function () {
    this.path = this.$route.meta.path;
    document.title = this.$route.meta.title;
  },
  methods: {
    examChoseName: function (examId, index) {
      return "chose[" + examId + "][" + parseInt(index + 1) + "]";
    },
    examChoseValue: function (index) {
      return String.fromCharCode(index + 65);
    },
    examChoseTitle: function (index, name) {
      return String.fromCharCode(index + 65) + "." + name;
    },
    formSubmit: function () {
      let that = this;
      layui.use(["form", "layer"], function () {
        let form = layui.form;
        let checkChoseStatus = $(
          ".all-ecstatus-content .chose .all-ecstatus-content-c span"
        );
        let isNotChoseExam = [];
        checkChoseStatus.each(function (index, element) {
          if (!$(element).hasClass("active")) {
            isNotChoseExam.push($(element).children("a").text());
          }
        });
        if (isNotChoseExam != "") {
          layer.open({
            type: 0,
            closeBtn: 0,
            title: "未答题提示",
            content:
              "您第 " +
              isNotChoseExam.toString() +
              " 道题还未做答，请继续答题！",
            skin: "layui-layer-lan",
          });
        } else {
          form.on("submit(examSubmit)", function (data) {
            $(data.elem).addClass("layui-btn-disabled");
            saveExam({ choses: that.uanswer }).then(function (res) {
              if (res.status == 200) {
                layer.open({
                  type: 0,
                  closeBtn: 0,
                  title: "答题结果",
                  content:
                    "<div><p>您的答题结果如下：</p><p>您一共答对 " +
                    res.data.user_correct_number +
                    " 道题、答错 " +
                    res.data.user_error_number +
                    " 道题、得分为：" +
                    res.data.user_score +
                    "</p><p>答错题的答案：" +
                    res.data.user_error_subject +
                    "</p></div>",
                  skin: "layui-layer-lan",
                  end: function () {
                    that.$router.go(0);
                  },
                });
              }
            });
            return false;
          });
        }
      });
    },
    naver: function (id) {
      $(".nice-scroll")
        .getNiceScroll(0)
        .doScrollTop($("#" + id).position().top + 60, 1000);
    },
  },
  updated: function () {
    layui.use(["form"], function () {
      let form = layui.form;
      form.render();
    });
    scroll($(".nice-scroll"));
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails,.layui-layer-shade").remove();
    layui.use(["layer"], function () {
      let layer = layui.layer;
      layer.closeAll();
    });
  },
};

$(document).ready(function () {
  $(document).bind("contextmenu", function (e) {
    return false;
  });
  $(document).bind("selectstart", function () {
    return false;
  });
  $(document).keydown(function (e) {
    return key(arguments[0]);
  });

  function key(e) {
    var e = window.event || e,
      keynum = window.event ? e.keyCode : e.which;
    if ((e.ctrlKey && e.keyCode == 82) || keynum == 116) {
      return false;
    }
    if (keynum == 123) {
      return false;
    }
    if ((e.altKey && keynum == 37) || (e.altKey && keynum == 39)) {
      return false;
    }
    if (e.altKey && keynum == 115) {
      return false;
    }
  }
});
</script>

<style>
.exam {
  position: relative;
}

.exam .exam-title {
  font-size: 22px;
  font-family: HYQuanTangShiJ;
  display: block;
}
.exam .exam-chose {
  position: relative;
  margin-left: 20px;
  margin-top: 15px;
}

.exam .exam-chose .exam-chose-item + .exam-chose-item {
  position: relative;
  margin-top: 5px;
}

.exam-list + .exam-list {
  margin-top: 15px;
}

.monishiti .con_wrap {
  width: 100%;
  height: 1857px;
  background: url(../../assets/images/ceshiti.jpg);
  background-size: cover;
  background-position: center 0;
}
.con_box {
  width: 1300px;
  height: 1220px;
  margin: 0 auto;
  position: relative;
  top: 405px;
  padding: 80px 10px;
  box-sizing: border-box;
  overflow-y: auto;
  /* border: 1px solid blue; */
}
dl {
  font-size: 18px;
}

.left-fixed {
  position: fixed;
  right: 1%;
  top: 40%;
  transform: translateY(-50%);
  display: block;
  width: 140px;
}

.exam-left-time {
  position: relative;
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  margin-bottom: 15px;
}

.exam-left-time .exam-left-time-title {
  background-color: #5d979b;
  padding: 18px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
  border-bottom-color: #ffffff;
}

.exam-left-time .exam-left-time-content {
  padding: 10px;
}

.all-ecstatus {
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  display: inline-block;
  word-break: break-all;
  width: 140px;
}

.all-ecstatus .all-ecstatus-content {
  position: relative;
  font-size: 14px;
}

.all-ecstatus .chose {
  margin-bottom: 15px;
}

.all-ecstatus .all-ecstatus-title {
  background-color: #5d979b;
  padding: 13px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
  border-bottom-color: #ffffff;
}

.all-ecstatus .chose .all-ecstatus-content-c,
.all-ecstatus .judge .all-ecstatus-content-j {
  padding: 3px;
}

.all-ecstatus .chose .all-ecstatus-content-c span,
.all-ecstatus .judge .all-ecstatus-content-j span {
  border: 1px solid #dddddd;
  padding: 2px;
  margin-bottom: 3px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.all-ecstatus .all-ecstatus-content span.active {
  background-color: #5d979b;
}

.pull-right {
  position: absolute;
  margin: 50px;
  padding-bottom: 100px;
  display: block;
  right: 0;
}

.pull-right input {
  position: relative;
  float: right;
  right: 0;
}
.exam-picture {
  position: relative;
  margin-top: 15px;
}
.exam-picture img {
  display: inline;
  margin-right: 15px;
  margin-bottom: 15px;
}
</style>